;$(document).ready(function() {

    $('#hamburger').click(function(){
        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
            $('.navigation').hide();
        } else {
            $(this).addClass('open');
            $('.navigation').show();
        }
    });

    function showPreloader() {
        $('#preloader').css({
            'top': $('#query').offset().top + 4,
            'left': $('#query').offset().left + $('#query').width() - 16
        }).show();
    }

    $('#query').autocomplete({
        search: function() {
            showPreloader();
        },
        open: function() {
            $('#preloader').hide();
        },
        response: function(data) {
            if (data.result === undefined) {
                $('#preloader').hide();
            }
        },
        minLength: 1,
        source: function(request, response) {
            $.get('/ajax/' + $('#query').val(), function(data) {
                response(data);
            });
        },
        select: function(event, ui) {
            var url = '/artikkel/' + ui.item.slug + '.html';
            document.location.href = url;
        }
    }).autocomplete( "instance" )._renderItem = function( ul, item ) {
        return $( "<li>" ).append( "<a href=\"/artikkel/" + item.slug + ".html\">" + item.title + "</a>" ).appendTo( ul );
    };

    $('#county_id').on('change', function(event) {
        if ($(this).val() !== '') {
            document.location.href = '/' + $(this).val();
        }
    });

    $('.print').on('click', function(event) {
        event.preventDefault();
        window.print();
    });

    if (!/Mobi/.test(navigator.userAgent)) {
        $('.leaderboards').slick({
            autoplay: true,
            dots: true,
            speed: 800
        });
    }

    $('.resources').slick({
        autoplay: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 1000,
        speed: 800
    });

    $('img.lazy').lazyload();

    if (/Mobi/.test(navigator.userAgent)) {
        $('#questions').insertBefore($('#events'));
        $('#articles').insertBefore($('#questions'));
        $('#images').insertBefore($('#articles'));
        $('.searchbox').prependTo('.navigation').wrap('<li></li>');
    }
});